import React, { useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/user.service";
import { msalInstance, initializeMsal } from "./msalConfig";

const BASE_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      await initializeMsal();
    };

    initialize();
  }, []);

  const authenticateWithBackend = async (token, provider, name, email) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, provider }),
      });

      if (!response.ok) {
        throw new Error("Failed to authenticate with the backend");
      }

      const data = await response.json();
      const { jwt: customJwt, admin } = data;

      localStorage.setItem("token", customJwt);
      localStorage.setItem("userName", name);
      localStorage.setItem("userEmail", email);
      localStorage.setItem("admin", admin);

      navigate("/home");
      await userService.getLoggedInUser();
    } catch (error) {
      console.error("Error:", error);
      alert("Authentication failed");
      navigate("/");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    const googleToken = credentialResponse.credential;
    const decodedGoogleToken = jwtDecode(googleToken);
    const { name, email } = decodedGoogleToken;
    console.log("Decoded JWT:", decodedGoogleToken);

    await authenticateWithBackend(googleToken, "google", name, email);
  };

  const handleGoogleError = () => {
    console.log("Google login failed");
    alert("Google login failed");
  };

  const handleMicrosoftLogin = async () => {
    setLoading(true);
    try {
      const loginRequest = {
        scopes: ["openid", "profile", "User.Read"],
        prompt: "select_account",
      };

      const loginResponse = await msalInstance.loginPopup(loginRequest);
      const microsoftToken = loginResponse.idToken;

      console.log("Microsoft loginResponse", loginResponse);

      const { name, username: email } = loginResponse.account;

      await authenticateWithBackend(microsoftToken, "microsoft", name, email);
    } catch (error) {
      if (error?.errorCode === "interaction_in_progress") {
        console.error("A login process is already in progress.");
        alert("A login process is already in progress. Please complete it before trying again.");
      } else {
        console.error("Error:", error);
        alert("Microsoft Authentication failed");
        navigate("/");
      }
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        height: "250px",
        overflow: "hidden",
        margin: "auto",
        paddingTop: "40px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: 3,
          color: "primary.main",
        }}
      >
        Login
      </Typography>

      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          <GoogleLogin
            onSuccess={handleGoogleSuccess}
            onError={handleGoogleError}
          />
          <Button
            variant="outlined"
            endIcon={
              <img
                src="https://img.icons8.com/color/48/000000/microsoft.png"
                alt="Microsoft logo"
                style={{ width: "20px" }}
              />
            }
            color="primary"
            onClick={handleMicrosoftLogin}
            sx={{
              marginTop: 2,
              color: "gray",
              fontSize: "11px",
              borderColor: "rgba(0, 0, 0, 0.23)",
            }}
          >
            Login with Microsoft
          </Button>
        </>
      )}
    </Box>
  );
};

export default Login;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@mui/material";
import "./product-summary.css";

const ProductSummary = ({ item }) => {
  // console.log("ProductSummary", item);

  const [selectedClaim, setSelectedClaim] = useState(1);

  // const handleTabChange = (event, newValue) => {
  //   setSelectedClaim(newValue);
  // };

  for (let linkIndex in item.links) {
    let index = parseInt(linkIndex, 10) + 1;
    item.summary = item.summary.replaceAll(
      `[${index}]`,
      `<sup class="pill"><a href="${
        item.links[index - 1]
      }" target="_blank">${index}</a></sup>`
    );
  }

  const getScorePill = (score) => {
    if (score < 0) {
      return "pill pill-unknown";
    } else if (score < 0.5) {
      return "pill pill-low";
    } else if (score < 1) {
      return "pill pill-medium";
    } else {
      return "pill pill-high";
    }
  };

  const getScoreText = (score) => {
    if (score < 0) {
      return "X";
    } else if (score < 0.5) {
      return "L";
    } else if (score < 1) {
      return "M";
    } else {
      return "H";
    }
  };

  return (
    <div className="product-summary">
      <h4>Product Summary</h4>
      <div dangerouslySetInnerHTML={{ __html: item.summary }}></div>
      {/* <p>
        {item.links.map((link, index) => {
          return (
            <React.Fragment key={"link." + index}>
              <div>({index})
              <a key="{'link.'+index}" href={link} target="_blank">
                {link}
              </a></div>
            </React.Fragment>
          );
        })}
      </p> */}

      <Tabs
        onChange={(event, newValue)=>setSelectedClaim(newValue)}
        value={"" + selectedClaim}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Claims Tabs"
        sx={{
          "& .MuiTabs-indicator": {
            backgroundColor: "primary.main",
          },
          "& .MuiTab-root": {
            textTransform: "none",
            fontWeight: "bold",
          },
          "& .Mui-selected": {
            color: "primary.main",
          },
          "& .MuiTabScrollButton-root": {
            color: "primary.main",
          },
        }}
      >
        {Object.entries(item.analysis).map(
          (claimAnalysis) => {
            let key = claimAnalysis[0];
            claimAnalysis = claimAnalysis[1];
            // console.log(
            //   "ProductSummary.item.analysis",
            //   claimAnalysisIndex,
            //   claimAnalysisIndex+1,
            //   key,
            //   key+1,
            //   selectedClaim,
            //   selectedClaim+1,
            //   claimAnalysis,
            //   item.analysis[key]
            // );
            return (
              <Tab
                key={key}
                label={
                  <span>
                    claim {key}{" "}
                    <span className={getScorePill(claimAnalysis.score)}>
                      {getScoreText(claimAnalysis.score)}
                    </span>
                  </span>
                }
                value={key}
                disabled={false}
                // sx={{ color: "#2393d8" }}
              />
            );
          }
        )}
      </Tabs>

      {Object.entries(item.analysis).map(
        (claimAnalysis, claimAnalysisIndex) => {
          let key = claimAnalysis[0];
          claimAnalysis = claimAnalysis[1];
          return (
            <TabPanel
              key={"Claim " + key}
              value={claimAnalysis}
              index={key}
              selectedClaim={selectedClaim}
            />
          );
        }
      )}
    </div>
  );
};

ProductSummary.propTypes = {
  item: PropTypes.object.isRequired,
};

const TabPanel = ({ value, index, selectedClaim }) => {
  TabPanel.propTypes = {
    value: PropTypes.any.isRequired,
    index: PropTypes.any.isRequired,
    selectedClaim: PropTypes.any.isRequired,
  };
  // console.log("TabPanel", index, selectedClaim, value);
  return (
    <div role="tabpanel" hidden={selectedClaim !== index}>
      {selectedClaim === index && (
        <Box pl={3}>
          <div className="similarity-header">Possible Similarities:</div>
          {value.similarities.map((similarity, similarityIndex) => (
            <Box key={"similarity." + similarityIndex}>
              {similarityIndex > 0 && <hr />}
              <div>
                <strong>Reasoning:</strong>{" "}
                {similarity.reasoning}
              </div>
              <div>
                <strong>Claim Reference:</strong> {similarity.claim_reference}
              </div>
              <div>
                <strong>Summary Reference:</strong>{" "}
                {similarity.summary_reference}
              </div>
            </Box>
          ))}

          <div className="similarity-header">Possible Differences:</div>
          {value.differences.map((difference, differenceIndex) => (
            <Box key={"similarity." + differenceIndex}>
              {differenceIndex > 0 && <hr />}
              <div>
                <strong>Reasoning:</strong>{" "}
                {difference.reasoning}
              </div>
              <div>
                <strong>Claim Reference:</strong> {difference.claim_reference}
              </div>
              <div>
                <strong>Summary Reference:</strong>{" "}
                {difference.summary_reference}
              </div>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

export default ProductSummary;

import React, { useState } from "react";
import { Box } from "@mui/material";
import * as Accordion from "@radix-ui/react-accordion";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import PropTypes from "prop-types";
import "./patent-summary.css";

import ProductSummary from "./product-summary";

const PatentSummary = ({ searchResults }) => {
  console.log("PatentSummary searchResults:", searchResults);

  const [hasSummary, setHasSummary] = useState(searchResults?.summary != null);

  const getScore = (item) => {
    let score = {
      high: 0,
      medium: 0,
      low: 0,
      total: 0,
      label: "?",
      count: 0,
      locals: []
    };
    if (item?.analysis != null) {
      for (let index in item.analysis) {
        let analysis = item.analysis[index];
        let localScore = parseFloat(analysis.score);
        score.locals.push (analysis.score);
        score.total += localScore;
        if (localScore >= 1) {
          score.high++;
        }
        if (localScore == 0.5) {
          score.medium++;
        }
        if (localScore == 0) {
          score.low++;
        }
        score.count++;
      }

      if (score.high > 0) {
        score.label = "H";
      } else if (score.medium > 0) {
        score.label = "M";
      } else if (score.low > 0) {
        score.label = "L";
      } else {
        score.label = "X";
      }
    }
    // console.log("score:", score, item.analysis);
    return score;
  };

  if (hasSummary) {
    searchResults.summary.results.map((item) => {
      item.score = getScore(item);
    });
    searchResults.summary.results.sort((a, b) => {
      if (a.score.label === "?" && b.score.label !== "?") return 1;
      if (a.score.high > b.score.high) return -1;
      if (a.score.high < b.score.high) return 1;
      if (a.score.medium > b.score.medium) return -1;
      if (a.score.medium < b.score.medium) return 1;
      if (a.score.low > b.score.low) return -1;
      if (a.score.low < b.score.low) return 1;
      if (a.score.total > b.score.total) return -1;
      if (a.score.total < b.score.total) return 1;
      return 0;
    });
  }

  return (
    <>
      {hasSummary ? (
        <Accordion.Root
          className="AccordionRoot"
          type="single"
          defaultValue="all closed"
          collapsible
        >
          {searchResults
            ? searchResults.summary.results.map((item, index) => (
                <Accordion.Item
                  className="AccordionItem"
                  value={"item-" + index}
                  key={"item-" + index}
                >
                  <AccordionTrigger item={item} score={item.score} />
                  <Accordion.Content>
                    <ProductSummary item={item} />
                  </Accordion.Content>
                </Accordion.Item>
              ))
            : null}
        </Accordion.Root>
      ) : (
        <div>No summary available</div>
      )}
    </>
  );
};

PatentSummary.propTypes = {
  searchResults: PropTypes.object,
};

const AccordionTrigger = React.forwardRef(({ item, score, ...props }, ref) => {
  const getScorePill = () => {
    if (score.label == "L") {
      return "pill pill-low";
    }
    if (score.label == "M") {
      return "pill pill-medium";
    }
    if (score.label == "H") {
      return "pill pill-high";
    }
    return "pill pill-unknown";
  };

  return (
    <Accordion.Header className="AccordionHeader">
      {item.score.label !== "?" ? (
        <Accordion.Trigger className="AccordionTrigger" {...props} ref={ref}>
          <span>
            <span className={getScorePill()}>{score.label}</span>{" "}
            <span className="product-name">{item.product_common}</span> by{" "}
            <span className="vendor-name">{item.manufacturer_common}</span>
          </span>
          <ChevronDownIcon className="AccordionChevron" aria-hidden />
        </Accordion.Trigger>
      ) : (
        <Box className="AccordionTrigger" {...props} ref={ref}>
          <span>
            <span className={getScorePill()}>{score.label}</span>{" "}
            <span className="product-name">{item.product_common}</span> by{" "}
            <span className="vendor-name">{item.manufacturer_common}</span>
          </span>
          {/* <ChevronDownIcon className="AccordionChevron" aria-hidden /> */}
        </Box>
      )}
    </Accordion.Header>
  );
});

AccordionTrigger.propTypes = {
  item: PropTypes.object.isRequired,
  score: PropTypes.string.isRequired,
};

export default PatentSummary;

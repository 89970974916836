import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  useMediaQuery,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Typography,
  Drawer,
  Divider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Menu,
  Backdrop,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import PaginationFormControl from "../pagination-control";
import PatentInfo from "../patent-product/patent-info";
import PatentProductList from "./patent-product-list";
import PatentSummary from "./patent-summary";
import { utilsService } from "../../services/utils";
import { searchService } from "../../services/search.service";
import { useNavigate, useParams } from "react-router-dom";

const PatentSearchControls = ({
  claimsIndexes,
  choosenClaimIndex,
  hasSummary,
  searchResults,
  setChoosenClaimIndex,
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  searchProductTerm,
  setSearchProductTerm,
  PatentProducts,
  setPatentProducts,
  AllPatentProducts,
  setAllPatentProducts,
  error,
  setError,
  patentInfo,
  querySearch,
  setQuerySearch,
  dataSearchId,
  setDataSearchId,
  updatedAt,
  status,
  loading,
}) => {
  // Optional carousel toggle
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);

  // Right drawer for extra claim text
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Filters: Changed relevancy filter to allow multiple selections.
  const [filterRating, setFilterRating] = useState([]);
  const [filterFeedback, setFilterFeedback] = useState("");

  // Dialog for full claim text
  const [openFullClaim, setOpenFullClaim] = useState(false);
  const [fullClaimText, setFullClaimText] = useState("");

  // Popover for hover mini-menu (now also triggered on tab click)
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredClaim, setHoveredClaim] = useState(null);

  const [exportAnchorEl, setExportAnchorEl] = useState(null);

  // NEW: state for new report loading spinner
  const [isNewReportLoading, setIsNewReportLoading] = useState(false);

  // NEW: state for image popup dialog
  const [openImagePopup, setOpenImagePopup] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const [showingSummary, setShowingSummary] = useState(choosenClaimIndex == "summary");

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const params = useParams();
  const navigate = useNavigate();

  const showSummary = process.env.REACT_APP_SHOW_SUMMARY === 'true';

  useEffect(() => {
    handleSearchPatentProduct();
    // eslint-disable-next-line
  }, [filterRating, searchProductTerm, AllPatentProducts, filterFeedback]);

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Full-claim dialog open/close
  const handleOpenFullClaim = (text) => {
    setFullClaimText(text);
    setOpenFullClaim(true);
  };
  const handleCloseFullClaim = () => {
    setFullClaimText("");
    setOpenFullClaim(false);
  };

  // Popover open/close
  const handlePopoverOpen = (event, claim) => {
    setAnchorEl(event.currentTarget);
    setHoveredClaim(claim);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredClaim(null);
  };
  const popoverOpen = Boolean(anchorEl && hoveredClaim);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  // Sort: by like/dislike, then rating_auto
  const sortPatentProducts = (products) => {
    return products.slice().sort((a, b) => {
      if (a.rating === 1 && b.rating !== 1) return -1;
      if (b.rating === 1 && a.rating !== 1) return 1;
      if (a.rating === -1 && b.rating !== -1) return 1;
      if (b.rating === -1 && a.rating !== -1) return -1;
      if (a.rating === b.rating) {
        return b.rating_auto - a.rating_auto;
      }
      return b.rating - a.rating;
    });
  };

  const handleSearchPatentProduct = () => {
    const searchTermQueryLowerCase = searchProductTerm.toLowerCase();
    const filteredBySearchTerm = AllPatentProducts.filter((patent) =>
      [
        patent.title,
        patent.snippet,
        patent.manufacturer_auto,
        patent.product_auto,
        patent.source,
      ]
        .filter(Boolean)
        .some((field) => field.toLowerCase().includes(searchTermQueryLowerCase))
    );

    const filteredByRating = filteredBySearchTerm.filter((patent) => {
      if (filterRating.length > 0) {
        return filterRating.some((filter) => {
          if (filter === "rating_auto_greater_or_equal_0.75") {
            return patent.rating_auto >= 0.75;
          } else if (
            filter === "rating_auto_less_0.75_and_greater_or_equal_0.25"
          ) {
            return patent.rating_auto < 0.75 && patent.rating_auto >= 0.25;
          } else if (filter === "rating_auto_less_0.25") {
            return patent.rating_auto < 0.25;
          }
          return false;
        });
      }
      return true;
    });

    const filteredPatents = filteredByRating.filter((patent) => {
      if (filterFeedback === "1") {
        return patent.rating === 1;
      } else if (filterFeedback === "-1") {
        return patent.rating === -1;
      }
      return true;
    });

    setPatentProducts(sortPatentProducts(filteredPatents));
    setCurrentPage(1);
  };

  // Export to CSV
  const handleExportPatentProductsToExcel = () => {
    let allCategoryKeys = new Set();
    PatentProducts.forEach((patent) => {
      if (patent.category_auto) {
        Object.keys(patent.category_auto).forEach((key) => {
          allCategoryKeys.add(key);
        });
      }
    });
    allCategoryKeys = Array.from(allCategoryKeys);

    const exportData = PatentProducts.map((patent) => {
      const rowData = {
        Title: patent.title,
        Manufacturer: patent.manufacturer_auto || "",
        Product: patent.product_auto || "",
        Snippet: patent.snippet,
        Source: patent.source,
        Link: patent.link,
        score: patent.rating_auto,
      };
      allCategoryKeys.forEach((catKey) => {
        rowData[catKey] = patent.category_auto
          ? patent.category_auto[catKey] || 0
          : 0;
      });
      return rowData;
    });

    const headers = [
      "Powered by PioneerIP - Web based infringement search of granted and pending claims",
      "Visit us www.PioneerIP.com",
    ];

    utilsService.exportToCSV(
      exportData,
      `PioneerIP_${querySearch}_${choosenClaimIndex}`,
      headers
    );
  };

  // Like/dislike feedback
  const handleFeedbackUpdate = async (searchId, patentUid, newRating) => {
    const extractedSearchId = searchId.$oid || searchId;

    try {
      const updatedProducts = PatentProducts.map((patent) =>
        patent.uid === patentUid ? { ...patent, rating: newRating } : patent
      );
      setPatentProducts(sortPatentProducts(updatedProducts));

      setAllPatentProducts((prevProducts) =>
        prevProducts.map((pat) =>
          pat.uid === patentUid ? { ...pat, rating: newRating } : pat
        )
      );

      await searchService.updateUserRating(
        extractedSearchId,
        patentUid,
        newRating
      );
    } catch (error) {
      console.error("Failed to update rating: " + error.message);
    }
  };

  // Export Aggregated Report with spinner
  const handleExportAggregatedReport = async () => {
    try {
      setIsNewReportLoading(true); // start spinner
      const aggregatedData = await searchService.getAggregatedReport(
        dataSearchId
      );
      const blob = new Blob([aggregatedData.csv], {
        type: "text/csv;charset=utf-8;",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `PioneerIP_${querySearch}_manufacturers`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting aggregated report:", error);
    } finally {
      setIsNewReportLoading(false); // stop spinner
    }
  };

  // Handlers for export drop-down menu
  const handleExportButtonClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };
  const handleCloseExportMenu = () => {
    setExportAnchorEl(null);
  };

  const toggleCarouselVisibility = () => {
    setIsCarouselVisible(!isCarouselVisible);
  };

  // NEW: Handler to open image popup when an image in the carousel is clicked
  const handleImageClick = (imageUrl) => {
    console.log("Image clicked:", imageUrl);
    setSelectedImageUrl(imageUrl);
    setOpenImagePopup(true);
  };

  const currentItems =
    PatentProducts.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ) || [];

  const handleNavigateToPatentPage = () => {
    if (params.archiveId) {
      navigate("/patent-products");
    }
  };

  // NEW: central onChange handler for Tabs to both set the chosen claim & open popover
  const handleTabsChange = (event, newValue) => {
    console.log("--------------- tabs change", newValue);
    setChoosenClaimIndex(newValue);
    if (newValue !== "summary") {
      setShowingSummary(false);
      // Reset filters on tab change if needed
      setFilterRating([]);
      setFilterFeedback("");

      // find the clicked claim from claimsIndexes
      const clickedClaim = claimsIndexes.find(
        (c) => parseInt(c.claimIndex, 10) === newValue
      );
      if (clickedClaim) {
        // open the popover with the clicked claim
        handlePopoverOpen(event, clickedClaim);
      }
    } else {
      console.log("Summary tab clicked");
      setShowingSummary(true);
    }
  };

  return (
    <>
      {/* Backdrop spinner for New Report loading */}
      <Backdrop sx={{ color: "#fff", zIndex: 1500 }} open={isNewReportLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* IMAGE POPUP DIALOG */}
      <Dialog
        open={openImagePopup}
        onClose={() => setOpenImagePopup(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ textAlign: "center", backgroundColor: "#000" }}>
          <img
            src={selectedImageUrl}
            alt="Expanded view"
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "0 auto",
              display: "block",
            }}
          />
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          position: "sticky",
          top: "60px",
          zIndex: 100,
          backgroundColor: "white",
          padding: "10px 0",
          transform: "translateZ(0)",
        }}
      >
        {patentInfo && (
          <PatentInfo
            patentInfo={patentInfo}
            querySearch={querySearch}
            toggleCarouselVisibility={toggleCarouselVisibility}
            isCarouselVisible={isCarouselVisible}
            updatedAt={updatedAt}
            status={status}
            // Pass the new image click handler to the carousel component
            handleImageClick={handleImageClick}
          />
        )}

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box sx={{ width: 750, padding: "20px" }}>
            {claimsIndexes.map((claim) =>
              parseInt(claim.claimIndex, 10) === choosenClaimIndex ? (
                <Box key={claim.claimIndex} mb={3}>
                  <Typography variant="h6" color="primary">
                    Claim {claim.claimIndex}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography
                    variant="body1"
                    sx={{ lineHeight: 1.6, marginBottom: "16px" }}
                  >
                    {claim.claim_text}
                  </Typography>
                </Box>
              ) : null
            )}
          </Box>
        </Drawer>

        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          {AllPatentProducts.length > 0 && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Tabs
                  value={choosenClaimIndex}
                  onChange={handleTabsChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="Claims Tabs"
                  sx={{
                    "& .MuiTabs-indicator": {
                      backgroundColor: "primary.main",
                    },
                    "& .MuiTab-root": {
                      textTransform: "none",
                      fontWeight: "bold",
                    },
                    "& .Mui-selected": {
                      color: "primary.main",
                    },
                    "& .MuiTabScrollButton-root": {
                      color: "primary.main",
                    },
                  }}
                >
                  { showSummary? (
                  <Tab
                    key="summary"
                    disabled={!hasSummary}
                    value="summary"
                    label="Summary"
                    sx={{
                      color: "#2393d8",
                    }}
                    onClick={(event) => {
                      console.log("CLAIM!!!", patentInfo);
                    }}
                    title={hasSummary ? "Show Summary" : "No Summary available"}
                  />
                  ): null}
                  {claimsIndexes?.map((claim) => {
                    const claimIndex = parseInt(claim.claimIndex, 10);
                    return (
                      <Tab
                        key={claim.claimIndex}
                        disabled={claim.status !== "completed"}
                        value={claimIndex}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                color:
                                  claimIndex === choosenClaimIndex
                                    ? "#2393d8"
                                    : "gray",
                              }}
                            >
                              {claim.name}
                            </span>
                            <span
                              style={{
                                fontSize: "0.6em",
                                color:
                                  claimIndex === choosenClaimIndex
                                    ? "#2393d8"
                                    : "gray",
                              }}
                            >
                              {claim.status}
                            </span>
                          </Box>
                        }
                        onClick={(event) => {
                          // If this tab is already active, show a popup with the claim
                          if (claimIndex === choosenClaimIndex) {
                            handleOpenFullClaim(claim.claim_text);
                          }
                        }}
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </Box>
          )}
          {!showingSummary && AllPatentProducts.length > 0 && (
            <Box display="flex" alignItems="center">
              <PaginationFormControl
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                pageCount={Math.ceil(PatentProducts.length / itemsPerPage)}
                currentPage={currentPage}
                handleChangePage={handleChangePage}
              />
            </Box>
          )}
        </Box>

        {!showingSummary && (
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              {AllPatentProducts.length > 0 && (
                <InputBase
                  placeholder="Search Patent Products"
                  inputProps={{ "aria-label": "search" }}
                  sx={{
                    paddingLeft: "40px",
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid rgb(128,128,128, 0.3)",
                    position: "relative",
                    height: "40px",
                    marginRight: isSmallScreen ? "0" : "10px",
                  }}
                  value={searchProductTerm}
                  onChange={(e) => setSearchProductTerm(e.target.value)}
                  onKeyDown={(e) =>
                    e.key === "Enter" && handleSearchPatentProduct()
                  }
                />
              )}
              {AllPatentProducts.length > 0 && (
                <>
                  <FormControl
                    variant="outlined"
                    sx={{
                      width: "10em",
                      marginRight: "10px",
                    }}
                  >
                    <InputLabel id="feedback-select-label" shrink>
                      Feedback
                    </InputLabel>
                    <Select
                      labelId="feedback-select-label"
                      sx={{ height: "2.5rem" }}
                      name="feedback"
                      value={filterFeedback}
                      onChange={(e) => setFilterFeedback(e.target.value)}
                      label="Feedback"
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="1">Like</MenuItem>
                      <MenuItem value="-1">Dislike</MenuItem>
                    </Select>
                  </FormControl>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      margin: "0 10px",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography sx={{ color: "gray" }}>Relevancy:</Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ gap: "8px" }}
                    >
                      {/* High relevancy bubble */}
                      <Box
                        sx={{
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                          backgroundColor: "rgba(255, 0, 0, 0.5)",
                          color: "black",
                          fontWeight: "bold",
                          cursor: "pointer",
                          border: filterRating.includes(
                            "rating_auto_greater_or_equal_0.75"
                          )
                            ? "2px solid red"
                            : "none",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        }}
                        onClick={() => {
                          if (
                            filterRating.includes(
                              "rating_auto_greater_or_equal_0.75"
                            )
                          ) {
                            setFilterRating(
                              filterRating.filter(
                                (x) => x !== "rating_auto_greater_or_equal_0.75"
                              )
                            );
                          } else {
                            setFilterRating([
                              ...filterRating,
                              "rating_auto_greater_or_equal_0.75",
                            ]);
                          }
                        }}
                      >
                        <Typography
                          fontSize="9px"
                          sx={{ lineHeight: "normal" }}
                        >
                          High
                        </Typography>
                      </Box>

                      {/* Medium relevancy bubble */}
                      <Box
                        sx={{
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                          backgroundColor: "rgb(255, 255, 0)",
                          color: "black",
                          fontWeight: "bold",
                          cursor: "pointer",
                          border: filterRating.includes(
                            "rating_auto_less_0.75_and_greater_or_equal_0.25"
                          )
                            ? "2px solid red"
                            : "none",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        }}
                        onClick={() => {
                          if (
                            filterRating.includes(
                              "rating_auto_less_0.75_and_greater_or_equal_0.25"
                            )
                          ) {
                            setFilterRating(
                              filterRating.filter(
                                (x) =>
                                  x !==
                                  "rating_auto_less_0.75_and_greater_or_equal_0.25"
                              )
                            );
                          } else {
                            setFilterRating([
                              ...filterRating,
                              "rating_auto_less_0.75_and_greater_or_equal_0.25",
                            ]);
                          }
                        }}
                      >
                        <Typography
                          fontSize="9px"
                          sx={{ lineHeight: "normal" }}
                        >
                          Med
                        </Typography>
                      </Box>

                      {/* Low relevancy bubble */}
                      <Box
                        sx={{
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "30px",
                          height: "30px",
                          backgroundColor: "rgba(35, 147, 216, 0.5)",
                          color: "black",
                          fontWeight: "bold",
                          cursor: "pointer",
                          border: filterRating.includes("rating_auto_less_0.25")
                            ? "2px solid red"
                            : "none",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        }}
                        onClick={() => {
                          if (filterRating.includes("rating_auto_less_0.25")) {
                            setFilterRating(
                              filterRating.filter(
                                (x) => x !== "rating_auto_less_0.25"
                              )
                            );
                          } else {
                            setFilterRating([
                              ...filterRating,
                              "rating_auto_less_0.25",
                            ]);
                          }
                        }}
                      >
                        <Typography
                          fontSize="9px"
                          sx={{ lineHeight: "normal" }}
                        >
                          Low
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>{" "}
            {AllPatentProducts.length > 0 && (
              <Box sx={{ marginLeft: "auto" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleExportButtonClick}
                  startIcon={<DownloadIcon />}
                >
                  Export
                </Button>
                <Menu
                  anchorEl={exportAnchorEl}
                  open={Boolean(exportAnchorEl)}
                  onClose={handleCloseExportMenu}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseExportMenu();
                      handleExportPatentProductsToExcel();
                    }}
                  >
                    {`Claim ${choosenClaimIndex} results`}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseExportMenu();
                      handleExportAggregatedReport();
                    }}
                  >
                    Manufacturers (all claims)
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        )}
      </Box>

      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError("")}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          style={{ top: "100px", transform: "translateY(50px)" }}
        >
          <Alert
            onClose={() => setError("")}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      )}

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
              Almost there, showing results of your search...
            </Typography>
          </Box>
        </Box>
      ) : choosenClaimIndex === "summary" ? (
        <PatentSummary searchResults={searchResults} />
      ) : PatentProducts.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <Typography variant="h6" sx={{ color: "gray" }}>
            No results found.
          </Typography>
        </Box>
      ) : (
        <PatentProductList
          currentItems={currentItems}
          handleFeedbackUpdate={handleFeedbackUpdate}
          dataSearchId={dataSearchId}
          choosenClaimIndex={choosenClaimIndex}
          claimsIndexes={claimsIndexes}
          loading={loading}
        />
      )}

      {/* HOVER / CLICK POPOVER: truncated claim text + (more) */}
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        disableRestoreFocus
        PaperProps={{
          onMouseLeave: handlePopoverClose,
          sx: { maxWidth: 300, p: 2 },
        }}
      >
        {hoveredClaim && (
          <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
            {(() => {
              const words = hoveredClaim.claim_text?.split(" ") || [];
              const truncatedText = words.slice(0, 20).join(" ");
              const hasMore = words.length > 20;
              return hasMore ? truncatedText + "..." : truncatedText;
            })()}
            {(() => {
              const words = hoveredClaim.claim_text?.split(" ") || [];
              if (words.length > 20) {
                return (
                  <Typography
                    component="span"
                    sx={{ color: "primary.main", cursor: "pointer", ml: 1 }}
                    onClick={() => {
                      handlePopoverClose();
                      handleOpenFullClaim(hoveredClaim.claim_text);
                    }}
                  >
                    (more)
                  </Typography>
                );
              }
              return null;
            })()}
          </Typography>
        )}
      </Popover>

      {/* FULL CLAIM DIALOG */}
      <Dialog
        open={openFullClaim}
        onClose={handleCloseFullClaim}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Full Claim</DialogTitle>
        <DialogContent dividers>
          <DialogContentText sx={{ whiteSpace: "pre-wrap", lineHeight: 1.6 }}>
            {fullClaimText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseFullClaim}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

PatentSearchControls.propTypes = {
  searchResults: PropTypes.object,
};

export default PatentSearchControls;

import React, { useEffect, useState, useContext } from "react";
import {
  Typography,
  Container,
  Box,
  List,
  InputBase,
  useMediaQuery,
  Tooltip,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { searchService } from "../services/search.service";
import ProgressBar from "../components/progressBar";
import SearchIcon from "@mui/icons-material/Search";
import { utilsService } from "../services/utils";
import PaginationFormControl from "../components/pagination-control";
import { showErrorMsg } from "../services/event-bus.service";
import { useParams } from "react-router-dom";
import { StatusUpdatesContext } from "./../services/search.status.updates.context";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArchiveList from "../components/archive/archive-list";

const showSummary = process.env.REACT_APP_SHOW_SUMMARY === 'true';

const ArchiveIndex = () => {
  const [allSearchIds, setAllSearchIds] = useState([]); // All IDs fetched from backend
  const [searchIds, setSearchIds] = useState([]); // IDs to display after filter
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const pageCount = Math.ceil(searchIds.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchIds.slice(indexOfFirstItem, indexOfLastItem);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:800px)");
  const patentNumberParams = useParams().patentNumber;
  const [userInteracted, setUserInteracted] = useState(false);
  const { activeSearchesId } = useContext(StatusUpdatesContext);
  const { activeSearchesRecord } = useContext(StatusUpdatesContext);
  const [expandedItems, setExpandedItems] = useState({});

  //Filters variables
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const storageToken = localStorage.getItem("token");
  useEffect(() => {
    if (!storageToken) {
      navigate("/");
    }
  }, [storageToken]);
  useEffect(() => {
    if (!userInteracted && patentNumberParams) {
      setSearchTerm(patentNumberParams); // Just set the searchTerm, no need to trigger search here
    }
  }, [patentNumberParams]);

  // New useEffect to trigger search when searchTerm is updated
  useEffect(() => {
    handleSearch();
  }, [searchTerm, fromDateFilter, toDateFilter, allSearchIds]);

  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const userEmail = localStorage.getItem("userEmail");

    // Redirect to login if no user data is present
    if (!userName && !userEmail) {
      navigate("/");
    }
    handleGetUserResults();
  }, []);

  useEffect(() => {
    UpdateEnireDataStructureOfSearch();
  }, [activeSearchesId, activeSearchesRecord]);

  //When an update status of a search arrived from the server (Using the aearch.status.updates.context)
  //we need to update the list of all history items in the page.

  function UpdateEnireDataStructureOfSearch() {
    try {
      activeSearchesRecord.forEach((newItem) => {
        let index = allSearchIds.findIndex(
          (item) => item.search_id === newItem.search_id
        );
        if (index !== -1) {
          allSearchIds[index] = newItem;
        } else {
          allSearchIds.push(newItem);
        }

        index = searchIds.findIndex(
          (item) => item.search_id === newItem.search_id
        );
        if (index !== -1) {
          searchIds[index] = newItem;
        } else {
          searchIds.push(newItem);
        }
      });
    } catch (error) {
      console.error(
        "An error occurred in UpdateEnireDataStructureOfSearch:",
        error
      );
    }
  }

  const handleGetUserResults = async () => {
    setLoading(true);
    try {
      const fetchedSearchIds = await searchService.getUserResults("");
      console.log("fetchSearchIds", fetchedSearchIds);
      setAllSearchIds(fetchedSearchIds);
      setSearchIds(fetchedSearchIds);
    } catch (error) {
      console.error("Failed to fetch user results: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const searchInField = (field, searchTerm) => {
    if (!field) return false;
    return field.toLowerCase().includes(searchTerm);
  };

  const handleSearch = () => {
    if (!allSearchIds.length) return;

    setUserInteracted(true);

    // If all filters are empty, show all
    if (searchTerm.trim() === "" && !fromDateFilter && !toDateFilter) {
      setSearchIds(allSearchIds);
      setCurrentPage(1);
      return;
    }

    const searchTermLowerCase = searchTerm.toLowerCase().trim();
    const filteredIds = allSearchIds.filter((pat) => {
      // Search in multiple fields
      const fieldsToSearch = {
        patentNumber: pat.query.patent_number,
        assignee: pat.query.assignee,
        title: pat.title, // Add title to search fields
      };

      const textMatches =
        searchTermLowerCase === "" ||
        Object.values(fieldsToSearch).some((field) =>
          searchInField(field, searchTermLowerCase)
        );

      // Date filtering
      const date = new Date(pat.updated_at);
      const from = fromDateFilter ? new Date(fromDateFilter) : null;
      const to = toDateFilter ? new Date(toDateFilter) : null;
      const dateMatches = (!from || date >= from) && (!to || date <= to);

      return textMatches && dateMatches;
    });

    setSearchIds(filteredIds);
    setCurrentPage(1);
  };

  const handleOpenPatent = async (archiveId, itemStatus, claimIndex) => {
    let url = `/patent-products/${archiveId}?status=${itemStatus}&claimIndex=`;
    if (showSummary) {
      if (claimIndex == "summary") {
        url += "1&summary=true";
      } else {
        url += claimIndex;
      }
    } else {
      url += 1;
    }
    navigate(url);
  };

  if (loading) {
    return (
      <Box
        sx={{
          marginTop: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ color: "primary.main" }} />
        <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
          Almost there, showing results of your search...
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ marginTop: "30px" }}>
      <Box
        sx={{
          position: "sticky",
          top: "100px",
          zIndex: "100",
          backgroundColor: "white",
          padding: "10px 0",
          borderBottom: "1px solid #ccc",
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              position: "relative",
              borderRadius: "5px",
              marginLeft: "20px",
              width: "30%",
            }}
          >
            <div
              style={{
                position: "absolute",
                height: "100%",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SearchIcon />
            </div>

            <InputBase
              placeholder="Search Archive Items"
              inputProps={{ "aria-label": "search" }}
              sx={{
                color: "inherit",
                paddingLeft: "40px",
                width: "100%",
                borderRadius: "5px",
                fontSize: isSmallScreen ? "10px" : "16px",
                position: "relative",
              }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          {searchIds.length > 0 && (
            <Box display="flex" alignItems="center">
              <PaginationFormControl
                itemsPerPage={itemsPerPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
                pageCount={pageCount}
                currentPage={currentPage}
                handleChangePage={handleChangePage}
              />
            </Box>
          )}
        </Box>
      </Box>
      <ArchiveList
        currentItems={currentItems}
        isSmallScreen={isSmallScreen}
        handleOpenPatent={handleOpenPatent}
        expandedItems={expandedItems}
        setExpandedItems={setExpandedItems}
      />
    </Container>
  );
};

export default ArchiveIndex;
